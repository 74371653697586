import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReplyStore from '../../../../ChatReplyStore';
import { ChatMessageReplyResource, ChatMessageResource } from '../../../../ConversationStore/Types';
import { FlashMessage } from '../../../FlashMessage';
import DeleteMessageModal from '../DeleteMessageModal';
import ReportMessageModal from '../ReportMessageModal';
import SubscriptionStore from '../SubscriptionStore';
import ActionMenuBase from './ActionMenuBase';
import ActionMenuReactionList from './ActionMenuReactionList';
import ActionMenuStore from './ActionMenuStore';

interface Props {
  store: ActionMenuStore;
  messageResource: ChatMessageResource | ChatMessageReplyResource;
  chatReplyStore: ChatReplyStore;
  subscriptionStore: SubscriptionStore;
}

const ActionMenuStates: React.FC<Props> = ({
  store,
  messageResource,
  chatReplyStore,
  subscriptionStore,
}) => {
  switch (store.state.kind) {
    case 'deleting-message':
    case 'reaction-selected':
    case 'ready':
      return (
        <ActionMenuBase
          store={store}
          messageResource={messageResource}
          chatReplyStore={chatReplyStore}
          subscriptionStore={subscriptionStore}
        />
      );
    case 'reaction-menu-open':
      return (
        <ActionMenuReactionList
          actionMenuStore={store}
          reactionsResource={messageResource.payload.reactions}
        />
      );
    case 'message-delete-requested':
      return <DeleteMessageModal actionMenuStore={store} links={messageResource.links} />;
    case 'message-report-requested':
    case 'reporting-message':
    case 'message-report-success':
      return (
        <ReportMessageModal
          actionMenuStore={store}
          links={messageResource.payload.offensiveMessageReports.links}
        />
      );
    case 'error':
      return store.notification
        .map((alert) => <FlashMessage alert={alert} />)
        .getOrElse(() => <></>);
    default:
      return assertNever(store.state);
  }
};

export default observer(ActionMenuStates);
