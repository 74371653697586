import { observer } from 'mobx-react';
import { useContext } from 'react';
import { FlashMessage } from '../components/FlashMessage';
import { NotificationsContext } from './NotificationsContext';

function Notifications() {
  const notificationsStore = useContext(NotificationsContext);

  return (
    <>
      {notificationsStore.notifications.map((flash) => (
        <FlashMessage key={flash.uuid} alert={flash} />
      ))}
    </>
  );
}

export default observer(Notifications);
