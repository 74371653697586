import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FlashMessage } from '../../components/FlashMessage';
import { NotifiableStore } from '../../ErrorActionableReaction';
import ExecOnlineLogo from './ExecOnlineLogo';
import RegistrationHeaderTitleAndDescription from './RegistrationHeaderTitleAndDescription';
import { SharedInvitationResource } from './SharedOpenEnrollment/SharedInvitationResourceStore/Types';

interface Props {
  notifiableStore: NotifiableStore;
  sharedInvitationResource: SharedInvitationResource;
}

const RegistrationFormHeader: React.FC<Props> = ({
  notifiableStore,
  sharedInvitationResource,
  children,
}) => (
  <>
    <div className="mb-10">
      <ExecOnlineLogo size="small" />
    </div>
    <div className="mb-10">
      <RegistrationHeaderTitleAndDescription sharedInvitationResource={sharedInvitationResource} />
      {children}
    </div>
    {notifiableStore.notification
      .map((alert) => <FlashMessage alert={alert} />)
      .getOrElse(emptyFragment)}
  </>
);

export default observer(RegistrationFormHeader);
