import { observer } from 'mobx-react';
import * as React from 'react';
import { error } from '../../../ErrorHandling';
import { errorAlert } from '../../../Notifications/Types';
import ResourceStore from '../../../ResourceStore';
import ContentFaker from '../../ContentFaker';
import { FlashMessage } from '../../FlashMessage';
import ResourceItem from '../ResourceItem';

interface Props {
  resourceStore: ResourceStore;
}

const ResourceView: React.FC<Props> = ({ resourceStore }) => {
  switch (resourceStore.state.kind) {
    case 'ready':
      return <ResourceItem resource={resourceStore.state.resourceResource.payload} />;
    case 'waiting':
    case 'loading':
      return <ContentFaker />;
    case 'error':
      return <FlashMessage alert={errorAlert(error('Something went wrong'))} />;
  }
};

export default observer(ResourceView);
