import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useExperienceContext } from '../../../Common/Experience/ExperienceContext';
import {
  EnrollmentModalContextOptions,
  ExperienceEnrollmentContextProvider,
} from '../../../Common/ProgramExperienceEnrollment';
import EnrollmentStore from '../../../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../../../DiscoveryPortal/RegisterResourceStore/Types';
import HeroBackground from './HeroBackground';
import HeroBanner from './HeroBanner';
import HeroBody from './HeroBody';
import HeroBreadcrumbs from './HeroBreadcrumbs';
import { getHeroSectionBackgroundColor } from './Types';

interface Props {
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
}

const options: EnrollmentModalContextOptions = {
  programFooterButtons: nothing(),
  scheduledExperienceOptions: nothing(),
};

function ExperienceHeroView({ enrollmentStore, experiencesStore, registerResource }: Props) {
  const { invitationUuid, experienceResource } = useExperienceContext();

  useEffect(() => {
    enrollmentStore.settingUp(experiencesStore.useCase);
  }, [experiencesStore.useCase]);

  return (
    <ExperienceEnrollmentContextProvider
      value={{
        invitationUuid,
        experienceResource,
        enrollmentStore,
        experiencesStore,
        options,
      }}
    >
      <div className={getHeroSectionBackgroundColor(experienceResource.payload)}>
        <div className="mx-auto xl:container">
          <HeroBackground experience={experienceResource.payload}>
            <div aria-live="polite">
              <HeroBanner experience={experienceResource.payload} />
            </div>
            <div className="box-border w-full px-4 py-12 sm:px-12 sm:pb-16 sm:pt-8">
              <HeroBreadcrumbs
                invitationUuid={invitationUuid}
                experience={experienceResource.payload}
                registerInfo={registerResource.payload}
              />
              <HeroBody experience={experienceResource.payload} />
            </div>
          </HeroBackground>
        </div>
      </div>
    </ExperienceEnrollmentContextProvider>
  );
}

export default observer(ExperienceHeroView);
