import { emptyFragment, ScrollBox } from '@execonline-inc/execonline-ui';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { ExperienceHtmlView } from '../../../ExperienceDetailView/Common';

interface Props {
  value: Maybe<AlreadyTranslatedText>;
}

function PromotableExperienceTileDescription({ value }: Props) {
  return value
    .map((description) => (
      <ScrollBox className="h-40 sm:h-32">
        <ExperienceHtmlView data={description} />
      </ScrollBox>
    ))
    .getOrElse(emptyFragment);
}

export default observer(PromotableExperienceTileDescription);
