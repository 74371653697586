import { v4 as uuid } from 'uuid';
import { Error, ServerError } from '../ErrorHandling';
import { PaymentLoadingError } from '../Native/AEP/DiscoveryPortal/Enrollment/Store/Types';
import { TPlainTextKey } from '../Translations';

export interface WarningAlert {
  kind: 'warning-alert';
  uuid: string;
  message: TPlainTextKey;
}

export interface SuccessAlert {
  kind: 'success-alert';
  uuid: string;
  message: TPlainTextKey;
}

export const successAlert = (message: TPlainTextKey): SuccessAlert => ({
  kind: 'success-alert',
  uuid: uuid(),
  message,
});

export interface InfoAlert {
  kind: 'info-alert';
  uuid: string;
  message: TPlainTextKey;
}

export interface ErrorAlert {
  kind: 'error-alert';
  uuid: string;
  error: Error;
}

export interface ServerErrorAlert {
  kind: 'server-error-alert';
  uuid: string;
  serverError: ServerError;
}

export interface PaymentLoadingErrorAlert {
  kind: 'payment-loading-error-alert';
  uuid: string;
  paymentLoadingError: PaymentLoadingError;
}

export const infoAlert = (message: TPlainTextKey): InfoAlert => ({
  kind: 'info-alert',
  message,
  uuid: uuid(),
});

export const warningAlert = (message: TPlainTextKey): WarningAlert => ({
  kind: 'warning-alert',
  message,
  uuid: uuid(),
});

export const errorAlert = (error: Error): ErrorAlert => ({
  kind: 'error-alert',
  error,
  uuid: uuid(),
});

export const serverErrorAlert = (serverError: ServerError): ServerErrorAlert => ({
  kind: 'server-error-alert',
  serverError,
  uuid: uuid(),
});

export type FlashAlert =
  | WarningAlert
  | SuccessAlert
  | InfoAlert
  | ErrorAlert
  | ServerErrorAlert
  | PaymentLoadingErrorAlert;

export const paymentLoadingErrorAlert = (
  paymentLoadingError: PaymentLoadingError,
): PaymentLoadingErrorAlert => ({
  kind: 'payment-loading-error-alert',
  paymentLoadingError,
  uuid: uuid(),
});
