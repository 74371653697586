import { observer } from 'mobx-react';
import { ExperienceVideoPreview } from '../../../Common/ProgramExperienceVideo';

function ExperienceVideo() {
  return (
    <ExperienceVideoPreview className="mt-10 h-[12.125rem] w-86 flex-shrink-0 rounded-3xl sm:h-[16.25rem] sm:w-115 lg:mt-0" />
  );
}

export default observer(ExperienceVideo);
