import { observer } from 'mobx-react';
import { FlashMessage } from '../../components/FlashMessage';
import { errorAlert } from '../../Notifications/Types';
import { UnifiedLoginError } from '../Errors';
import { getErrorMessage } from './Functions';

interface Props {
  error: UnifiedLoginError;
}

function ErrorMessage({ error }: Props) {
  return (
    <div className="flex h-screen items-center justify-center">
      <FlashMessage alert={errorAlert(getErrorMessage(error))} />
    </div>
  );
}

export default observer(ErrorMessage);
