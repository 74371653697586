import { observer } from 'mobx-react';
import { ExperienceVideoPreview, ExperienceViewLogo } from '../../../ProgramExperienceVideo';

interface Props {
  className: string;
  height: number;
}

function ProgramExperienceCardVideo({ className, height }: Props) {
  return (
    <ExperienceVideoPreview className={className}>
      <ExperienceViewLogo className={className} height={height} />
    </ExperienceVideoPreview>
  );
}

export default observer(ProgramExperienceCardVideo);
