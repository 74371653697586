import { Error, error } from '../../ErrorHandling';
import { UnifiedLoginError } from '../Errors';

export const getErrorMessage = (loginError: UnifiedLoginError): Error => {
  switch (loginError.kind) {
    case 'invalid-rel-error':
    case 'unknown-app-error':
      return error('The login request did not specify a valid application.');
    case 'window-interaction-error':
      return error('Unable to redirect back to the application.');
  }
};
