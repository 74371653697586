import { observer } from 'mobx-react';
import * as React from 'react';
import Loading from 'react-loading';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import TeamsStore from '../../TeamsStore';
import { T } from '../../Translations';
import * as style from '../Conference/style.module.css';
import { FlashMessage } from '../FlashMessage';
import BreakoutRedirect from './BreakoutRedirect';

interface Props {
  teamsStore: TeamsStore;
  breakoutStore: BreakoutChannelStore;
}
class BreakoutButtonStates extends React.Component<Props> {
  loadTeams = () => {
    this.props.teamsStore.loading();
  };

  render() {
    const { breakoutStore, teamsStore } = this.props;
    const { breakout } = breakoutStore;

    switch (teamsStore.state.kind) {
      case 'waiting':
        return (
          <button
            className={style.joinBreakoutButton}
            data-test-breakout-room={breakout.teamId}
            onClick={this.loadTeams}
          >
            <T kind="Go To Breakout Room" />
          </button>
        );
      case 'loading':
      case 'loading-more':
        return (
          <>
            <button
              className={style.joinBreakoutButton}
              data-test-breakout-room={breakout.teamId}
              disabled={true}
            >
              <T kind="Go To Breakout Room" />
            </button>
            <Loading type="bubbles" height={45} width={50} />
          </>
        );
      case 'ready':
        return <BreakoutRedirect breakoutStore={breakoutStore} />;
      case 'error':
        return teamsStore.notification
          .map((alert) => <FlashMessage alert={alert} />)
          .getOrElseValue(<></>);
    }
  }
}

export default observer(BreakoutButtonStates);
