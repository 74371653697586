import { observer } from 'mobx-react';
import { ExperienceVideoPreview } from '../../../Common/ProgramExperienceVideo';
import { PromotableExperience } from '../Types';
import PromotableExperienceTileDescription from './PromotableExperienceTileDescription';
import PromotableExperienceTileFooter from './PromotableExperienceTileFooter';
import PromotableExperienceTileTitle from './PromotableExperienceTileTitle';

interface Props {
  experience: PromotableExperience;
}

function MobilePromotableExperienceTileView({ experience }: Props) {
  const { promotableMarketingTitle, promotableMarketingCopy } = experience;

  return (
    <section className="flex w-full min-w-[20.4375rem] flex-shrink-0 snap-start flex-col">
      <div className="relative mb-8 flex h-[12.125rem] items-center justify-center sm:h-52 md:mb-0">
        <span className="absolute inset-0 bg-promotable-carousel-mobile [clip-path:ellipse(110%_75%_at_70%_75%)]" />
        <ExperienceVideoPreview className="h-[12.125rem] w-86 rounded-sm" />
      </div>
      <PromotableExperienceTileTitle value={promotableMarketingTitle} />
      <PromotableExperienceTileDescription value={promotableMarketingCopy} />
      <PromotableExperienceTileFooter />
    </section>
  );
}

export default observer(MobilePromotableExperienceTileView);
