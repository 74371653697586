import { observer } from 'mobx-react';
import { FlashAlert } from '../../Notifications/Types';
import { AlreadyTranslated, NotTranslated, T, whenTPlainTextKey } from '../../Translations';

export interface Props {
  alert: FlashAlert;
}

function AlertMessage({ alert }: Props) {
  switch (alert.kind) {
    case 'payment-loading-error-alert':
      return <NotTranslated text={alert.paymentLoadingError.message} />;
    case 'server-error-alert':
      return <AlreadyTranslated content={alert.serverError.message} />;
    case 'error-alert':
      return whenTPlainTextKey(alert.error.message)
        .map((key) => <T kind={key} />)
        .getOrElse(() => <NotTranslated text={alert.error.message} />);
    case 'info-alert':
    case 'success-alert':
    case 'warning-alert':
      return whenTPlainTextKey(alert.message)
        .map((key) => <T kind={key} />)
        .getOrElse(() => <NotTranslated text={alert.message} />);
  }
}

export default observer(AlertMessage);
