import { observer } from 'mobx-react';
import { Experience } from '../../../../Common/Experience/Types';
import { LearnMoreProgramHero } from '../../../LearnMoreProgram';
import ExperienceProductDetails from './ExperienceProductDetails';

interface Props {
  experience: Experience;
}

function HeroBody({ experience }: Props) {
  switch (experience.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return <ExperienceProductDetails experience={experience} />;
    case 'aep':
    case 'epc':
    case 'msuite':
      return <LearnMoreProgramHero experience={experience} />;
  }
}

export default observer(HeroBody);
