import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { Experience } from '../../../Common/Experience/Types';
import { ExperienceLanguageAvailability, ExperienceTitle, ExperiencesLogo } from '../../Common';
import ExperienceVideo from '../../Common/ExperienceVideo';
import ExperienceActions from './ExperienceActions';
import ExperienceDescription from './ExperienceDescription';
import ExperienceOverview from './ExperienceOverview';

interface Props {
  experience: Experience;
}

function LearnMoreLeadershipHero({ experience }: Props) {
  return (
    <>
      <ExperiencesLogo experience={experience} />
      <div className="flex w-full flex-col gap-x-28 lg:flex-row">
        <div className="w-full lg:w-3/5">
          <ExperienceTitle className="text-white" title={just(experience.title)} />
          <ExperienceDescription
            className="mb-10 w-full sm:mb-12 sm:w-[85%]"
            description={experience.description}
          />
          <ExperienceLanguageAvailability
            experience={experience}
            className="mb-4 text-white sm:mb-8"
          />
          <ExperienceOverview experience={experience} />
        </div>
        <div className="mx-auto flex empty:hidden">
          <ExperienceVideo />
        </div>
      </div>
      <ExperienceActions experience={experience} />
    </>
  );
}

export default observer(LearnMoreLeadershipHero);
