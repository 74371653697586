import { Alert, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../../../../Translations';
import { PaymentCheckoutFormStore } from '../../../Common';

interface Props {
  store: PaymentCheckoutFormStore;
}

function PaymentErrorNotification({ store }: Props) {
  switch (store.state.kind) {
    case 'waiting':
    case 'ready-for-payment-confirmation':
    case 'confirming-payment':
      return emptyFragment();
    case 'payment-declined':
      return (
        <Alert data-testid="error-flash-message" status="error">
          <T kind={store.state.message} />
        </Alert>
      );
  }
}

export default observer(PaymentErrorNotification);
