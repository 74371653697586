import { AlertStatus } from '@execonline-inc/execonline-ui';
import { FlashAlert } from '../../Notifications/Types';

export const alertStatus = (alert: FlashAlert): AlertStatus => {
  switch (alert.kind) {
    case 'error-alert':
    case 'server-error-alert':
    case 'payment-loading-error-alert':
      return 'error';
    case 'info-alert':
      return 'info';
    case 'success-alert':
      return 'success';
    case 'warning-alert':
      return 'warning';
  }
};
