import { Alert } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { FlashAlert } from '../../Notifications/Types';
import AlertMessage from './AlertMessage';
import { alertStatus } from './Functions';

export interface Props {
  alert: FlashAlert;
}

function FlashMessage({ alert }: Props) {
  return (
    <Alert
      data-testid={`${alertStatus(alert)}-flash-message`}
      className="mx-auto mb-3 max-w-150 flex-col justify-center whitespace-pre-line text-center sm:mb-7"
      variant="outlined"
      status={alertStatus(alert)}
    >
      <AlertMessage alert={alert} />
    </Alert>
  );
}

export default observer(FlashMessage);
