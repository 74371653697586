import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PromotableExperience } from '../Types';
import MobilePromotableExperienceTileView from './MobilePromotableExperienceTileView';
import PromotableExperienceTileView from './PromotableExperienceTileView';

interface Props {
  experience: PromotableExperience;
}

function PromotableExperienceCarouselTile({ experience }: Props) {
  const breakpoint = useMediaQuery();

  switch (breakpoint) {
    case 'xl':
    case 'lg':
      return <PromotableExperienceTileView experience={experience} />;
    case 'md':
    case 'sm':
    case 'xs':
      return <MobilePromotableExperienceTileView experience={experience} />;
  }
}

export default observer(PromotableExperienceCarouselTile);
