import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { warningAlert } from '../../../../../Notifications/Types';
import { FlashMessage } from '../../../../../components/FlashMessage';
import { State } from '../../InvitationStore/Types';

interface Props {
  state: State;
}

function LockedUserMessage({ state }: Props) {
  switch (state.kind) {
    case 'registering':
    case 'requesting-profile-info':
    case 'authenticating-email-domain':
    case 'authenticating-user':
    case 'error':
    case 'instant-sso':
    case 'pending-email-confirmation':
    case 'requesting-profile-info-password-backend':
    case 'registered':
    case 'requesting-email-address':
    case 'validating-email-from-identified-user':
    case 'waiting':
      return emptyFragment();
    case 'requesting-profile-info-locked-access':
      return <FlashMessage alert={warningAlert('Your Account has been locked...')} />;
  }
}

export default observer(LockedUserMessage);
