import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import NotesStore from '../../../NotesStore';
import NoteStore from '../../../NoteStore';
import SegmentStore from '../../../SegmentStore';
import { SegmentResource } from '../../../SegmentStore/Types';
import { T } from '../../../Translations';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import { FlashMessage } from '../../FlashMessage';
import NoteReactions from '../../NoteReactions';
import NoteItem from '../../Notes/NoteItem';
import NotesReactions from '../../NotesReactions';
import NewNoteItem from './NewNoteItem';
import * as style from './style.module.css';

interface Props {
  segmentResource: SegmentResource;
  segmentStore: SegmentStore;
  notesStore: NotesStore;
}

@observer
class NotesTabPane extends React.Component<Props> {
  newNoteStore: NoteStore = new NoteStore(this.props.notesStore);

  componentDidMount() {
    this.props.notesStore.loading();
  }

  render() {
    return (
      <div className={style.tabContent} data-test-notes-tab-pane={true}>
        <div className={style.newNote} data-test-new-note={true}>
          <h3 className={style.supPanelHeader} data-test-new-note-header="Add Segment Note">
            <T kind="Add Segment Note" />
          </h3>
          {this.newNoteStore.notification
            .map((alert) => <FlashMessage alert={alert} />)
            .getOrElseValue(<></>)}
          <NewNoteItem noteStore={this.newNoteStore} />

          <NoteReactions
            store={this.newNoteStore}
            contextResource={just(this.props.segmentResource)}
            kettle={just(this.props.segmentStore.kettle)}
          />
        </div>
        <h3 className={style.supPanelHeader} data-test-notes-header="Notes in this segment">
          <T kind="Notes in this segment" />
        </h3>
        {this.props.notesStore.noteStores.map((noteStore: NoteStore) => {
          return (
            <div
              key={noteStore.noteResource
                .map((noteResource) => noteResource.payload.id)
                .getOrElseValue(0)}
            >
              <NoteItem noteStore={noteStore} hideContext={true} />
              <NoteReactions
                store={noteStore}
                contextResource={just(this.props.segmentResource)}
                kettle={nothing()}
              />
            </div>
          );
        })}
        <NotesReactions
          store={this.props.notesStore}
          resourceWithNotes={this.props.segmentResource}
        />
        <Link
          className={style.button}
          to="/notes"
          data-test-all-program-notes-link="View All Program Notes"
        >
          <T kind="View All Program Notes" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </Link>
      </div>
    );
  }
}
export default NotesTabPane;
