import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { InvitationStore } from '../InvitationStore';
import { FormState } from '../InvitationStore/Types';
import EmailForm from './EmailForm';
import FormFlashMessage from './FormFlashMessage';
import ProfileForm from './ProfileForm';

interface Props {
  store: InvitationStore;
  state: FormState;
}

function FormBody({ store, state }: Props) {
  switch (state.kind) {
    case 'requesting-email-address':
    case 'authenticating-email-domain':
      return <EmailForm store={store} state={state} />;
    case 'requesting-profile-info':
    case 'requesting-profile-info-locked-access':
    case 'requesting-profile-info-password-backend':
    case 'registering':
      return <ProfileForm store={store} state={state} />;
    case 'registered':
      return <FormFlashMessage />;
    case 'pending-email-confirmation':
      return emptyFragment();
  }
}

export default observer(FormBody);
