import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { FlashAlert } from '../../../../../../../Notifications/Types';
import { FlashMessage } from '../../../../../../../components/FlashMessage';

interface Props {
  notification: Maybe<FlashAlert>;
}

function ExperienceErrorNotification({ notification }: Props) {
  return notification.map((alert) => <FlashMessage alert={alert} />).getOrElse(emptyFragment);
}

export default observer(ExperienceErrorNotification);
