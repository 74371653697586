import { warn } from '@execonline-inc/logging';
import { useConst } from '@execonline-inc/react-hooks.private';
import { assertNever, pipe } from '@kofno/piper';
import { just } from 'maybeasy';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ok } from 'resulty';
import { setItem } from '../../../Storage';
import { useStorageWatching } from '../../../StorageWatcher';
import { StorageError, storageError } from '../../../StorageWatcher/Types';

import { infoAlert } from '../../../Notifications/Types';
import { FlashMessage } from '../../FlashMessage';
import Loader from '../../Loader';
import {
  State,
  error,
  ready,
  storageKey,
  uniqueConferenceId,
  whenCurrentSingletonConference,
} from './Types';

type Props = PropsWithChildren<{}>;

export function SingletonConference({ children }: Props) {
  const id = useConst(uniqueConferenceId);

  const watcher = useStorageWatching({
    config: { storageKey, storage: window.localStorage },
    state: ok(just(id)),
  });

  const [state, setState] = useState<State>({ kind: 'initializing' });

  useEffect(() => {
    const unsubscribe = watcher
      .focus(({ state }) => state)
      .watch((state) => {
        state
          .mapError(({ message }) =>
            storageError(`Unable to determine singleton conference from storage data: ${message}`),
          )
          .cata({ Err: pipe(error, setState), Ok: pipe(ready, setState) });
      });
    return () => unsubscribe();
  }, [watcher]);

  useEffect(() => {
    switch (state.kind) {
      case 'initializing':
        setItem(storageKey, id, watcher.value.config.storage)
          .mapError<StorageError>((msg) =>
            storageError(`Unable to set singleton conference ID in data storage: ${msg}`),
          )
          .fork(pipe(error, setState), pipe(just, ready, setState));
        break;
      case 'ready':
        break;
      case 'error':
        warn(state.err.message);
        break;
      default:
        assertNever(state);
    }
  }, [state, watcher]);

  switch (state.kind) {
    case 'initializing':
      return <Loader />;
    case 'ready':
      return whenCurrentSingletonConference(state.currentSingletonConferenceId, id)
        .map(() => <>{children}</>)
        .getOrElse(() => (
          <FlashMessage alert={infoAlert('This conference was automatically closed ...')} />
        ));
    case 'error':
      return <>{children}</>;
  }
}
