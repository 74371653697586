import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { FlashAlert } from '../../../../../Notifications/Types';
import { FlashMessage } from '../../../../../components/FlashMessage';

interface Props {
  notification: Maybe<FlashAlert>;
}

function Notification({ notification }: Props) {
  return notification
    .map((alert) => (
      <div className="flex justify-center">
        <FlashMessage alert={alert} />
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(Notification);
