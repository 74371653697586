import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { FlashMessage } from '../../../../components/FlashMessage';
import { NotifiableStore } from '../../../../ErrorActionableReaction';
import { InvitationStore } from '../../SharedOpenEnrollment/InvitationStore';
import FormFlashMessage from './FormFlashMessage';

interface Props {
  notifiableStore: NotifiableStore;
  invitationStore: InvitationStore;
}

function AlertMessage({ notifiableStore, invitationStore }: Props) {
  const state = invitationStore.state;
  switch (state.kind) {
    case 'pending-email-confirmation':
      return <FormFlashMessage />;
    case 'requesting-email-address':
    case 'requesting-profile-info':
    case 'waiting':
    case 'authenticating-email-domain':
    case 'validating-email-from-identified-user':
    case 'registering':
    case 'requesting-profile-info-locked-access':
    case 'requesting-profile-info-password-backend':
    case 'registered':
    case 'authenticating-user':
    case 'instant-sso':
    case 'error':
      return notifiableStore.notification
        .map((alert) => <FlashMessage alert={alert} />)
        .getOrElse(emptyFragment);
  }
}

export default observer(AlertMessage);
