import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FlashMessage } from '../../components/FlashMessage';
import { currentUserStore } from '../../CurrentUser/Store';
import { LoginStore } from '../Store';

interface Props {
  store: LoginStore;
}

const Notification: React.FC<Props> = ({ store }) => (
  <>
    {store.notification.map((alert) => <FlashMessage alert={alert} />).getOrElse(emptyFragment)}
    {currentUserStore.notification
      .map((alert) => <FlashMessage alert={alert} />)
      .getOrElse(emptyFragment)}
  </>
);

export default observer(Notification);
